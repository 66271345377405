<template>
    <div>
        <section class="hero">
            <div class="hero-body">
                <div class="">
                    <h1 class="title">
                        Projects
                    </h1>
                    <h2 class="subtitle">
                        Here are the Projects what I've worked on
                    </h2>
                </div>
            </div>
        </section>
        <div id="projects" class="container is-fluid">
            <div class="project-container">
                <div :class="projectCardClass"
                v-for="(project, projectIndex) in projects"
                :key="projectIndex">
                    <router-link :to="/project/+projectIndex">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-header-title">
                                    {{project.name}}
                                </div>
                                <div class="card-header-icon">
                                    <b-icon :icon="getIcon(project.type)"></b-icon>
                                </div>
                            </div>
                            <div class="card-image">
                                <div class="image" :style="{backgroundImage: 'url(/img/projects/'+projectIndex+'/main.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', height: '150px'}">
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <br>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        data(){
            return {
                projects: null,
                projectCardClass: null
            }
        },
        methods: {
            getData(){
                axios.get('projects.json')
                .then(response => {
                    this.projects = response.data
                })
            },
            changeClass(){
                if(screen.width < 650){
                    this.projectCardClass = 'project-card-small'
                } else if(screen.width < 1250){
                    this.projectCardClass = 'project-card-medium'
                } else {
                    this.projectCardClass = 'project-card'
                }
            },
            getIcon(type){
                var icon
                switch(type){
                    case 'bot':
                        icon = 'robot'
                    break;

                    case 'webpage':
                        icon = 'web'
                    break

                    case 'webshop':
                        icon = 'cart'
                    break

                    case 'erp':
                        icon = 'cloud-braces'
                    break

                    case 'open-source':
                        icon = 'open-source-initiative'
                    break

                    default:
                        icon = 'web'
                    break;
                }

                return icon
            }
        },
        created() {
            this.getData()
            this.changeClass()
            window.addEventListener("resize", this.changeClass)
        },
        destroyed() {
            window.removeEventListener("resize", this.changeClass)
        },
    }
</script>