<template>
	<div class="page-container">
		<div>
			<Header id="header"></Header>
			<div id="content">
				<div class="container is-fluid">
				<router-view id="content-place" class="main-content" />
				</div>
			</div>
		</div>
		<Footer id="footer" class="footer is-sticky"></Footer>
	</div>
</template>
<script>    
	import Header from './components/includes/Header'
	import Footer from './components/includes/Footer'

    export default {
        components: {
			Header,
			Footer
        },
    }
</script>