<template>
    <div class="">
        <div class="container is-fluid">
            <section class="hero">
                <div class="hero-body">
                    <div class="">
                        <h1 class="title">
                            {{project.name}}
                        </h1>
                        <h2 class="subtitle">
                            {{project.short}}
                        </h2>
                    </div>
                </div>
            </section>
            <div class="columns">
                <div class="column is-one-fifths">
                    <div class="box" v-if="project.updates !== null">
                        <div class="subtitle">
                            Updates:
                        </div>
                        <div class="card"
                        v-for="(projectUpdate, projectIndex) in project.updates"
                                :key="projectIndex">
                            <header class="card-header">
                                <span class="card-header-title">
                                    {{projectUpdate.title}}
                                </span>
                                <span class="card-header-icon">
                                    {{projectUpdate.date}}
                                </span>
                            </header>
                            <div class="card-content">
                                {{projectUpdate.description}}
                            </div>
                        </div>
                    </div>
                    <div class="box" v-if="project.links !== null">
                        <div class="subtitle">
                            Links
                        </div>
                        <ul>
                            <li
                                v-for="(link, projectIndex) in project.links"
                                :key="projectIndex">
                                    <a :href="link.link" target="_blank">{{link.name}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="box content">
                        <div class="subtitle">
                            Technology:
                        </div>
                        <ul>
                            <li
                                v-for="(projectResponsibility, projectIndex) in project.technology"
                                :key="projectIndex">
                                    {{projectResponsibility}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="column is-four-fifths">
                    <div class="box" v-if="project.images !== null">
                        <div>
                            <b-carousel 
                                :autoplay="true"
                                :pause-hover="false"
                                :arrow="true"
                                :interval="2500"
                                indicator-style="is-lines">
                                <b-carousel-item 
                                    v-for="(image, i) in project.images" :key="i">
                                    <span class="image is-text-centered">
                                        <img :src="'/img/projects/'+projectSlug+'/'+image" class="project-image">
                                    </span>
                                </b-carousel-item>
                            </b-carousel>
                        </div>
                    </div>
                    <div class="box">
                        <div class="subtitle">
                            About this project
                        </div>
                        <p>
                            <span v-html="project.description"></span>
                        </p>
                    </div>
                    <div class="box" v-if="project.type == 'bot' && project.commands !== null">
                        <div class="subtitle">
                            Commands
                        </div>
                        <table width="100%">
                            <tr
                                v-for="(command, projectIndex) in project.commands"
                                :key="projectIndex">
                                <td>
                                    {{command.command}}
                                </td>
                                <td>
                                    {{command.description}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data(){
            return {
                projectSlug: null,
                project: null,
            }
        },
        methods: {
            getData(){
                axios.get('/projects.json')
                .then(response => {
                    var data = response.data
                    this.project = data[this.projectSlug]
                })
            }
        },
        mounted() {
            this.projectSlug = this.$route.params.projectSlug
            this.getData()
        }
    }
</script>