import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Contacts from "../views/Contacts.vue";
import Projects from "../views/Projects.vue";
import Project from "../views/Project.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects
  },
  {
    path: "/project/:projectSlug",
    name: "Project",
    component: Project
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
