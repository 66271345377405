import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import './assets/sass/app.scss'
import Buefy from 'buefy'

Vue.use(Buefy, {
    defaultContainerElement: '#main-content',
})

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
